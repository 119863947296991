//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const add = (item) => {
  return ajax.post(`${base}/api/itedu/v1/user-learn-time/add`, item);
};

export const userLearnTimeApi = {
  // import时得花括号明确
  add: add,
};
