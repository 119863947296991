//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const add = (courseId, scheduleId, teachId, item) => {
  return ajax.post(
    `${base}/api/itedu/v1/danmu/add?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}`,
    item
  );
};

const queryAll = (courseId, scheduleId, teachId, vcode) => {
  return ajax.get(
    `${base}/api/itedu/v1/danmu/query-all?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}&vcode=${vcode}`
  );
};

export const danmuApi = {
  // import时得花括号明确
  add: add,
  queryAll: queryAll,
};
