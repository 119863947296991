import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const get = (courseId, scheduleId, teachId) => {
  return ajax.get(`${base}/api/itedu/v1/teach?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}`);
};

const vodUrl = (courseId, scheduleId, teachId) => {
  return ajax.get(`${base}/api/itedu/v1/teach/vodurl?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}`);
};

const vodParam = (courseId, scheduleId, teachId) => {
  return ajax.get(
    `${base}/api/itedu/v1/teach/vodparam?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}`
  );
};

const dogeCloudVodUrl = (courseId, scheduleId, teachId, platform) => {
  return ajax.get(
    `${base}/api/itedu/v1/teach/doge-cloud-vodurl?courseId=${courseId}&scheduleId=${scheduleId}&teachId=${teachId}&platform=${platform}`
  );
};

export const teachApi = {
  // import时得花括号明确
  get: get,
  vodUrl: vodUrl,
  vodParam: vodParam,
  dogeCloudVodUrl: dogeCloudVodUrl,
};
